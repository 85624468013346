@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap");
// themes - our custom or/and out of the box themes
@import "themes";

// framework component themes (styles tied to theme variables)
@import "@nebular/theme/styles/globals";
@import "@nebular/auth/styles/globals";

@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/variables-dark";
@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/grid";

// loading progress bar theme
@import "./pace.theme";

@import "./layout";
@import "./overrides";

// install the framework and custom global styles
@include nb-install() {
  // framework global styles
  @include nb-theme-global();
  @include nb-auth-global();

  @include ngx-layout();
  // loading progress bar
  @include ngx-pace-theme();

  @include nb-overrides();
}

* {
  transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

/* width */
::-webkit-scrollbar {
  width: 6px !important;
  height: 6px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #5eb747 !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #2865b0 !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #2865b0 !important;
}



@media screen and (min-width: 1940px) {
  nb-layout.window-mode.with-scroll {
    padding-top: 0px;
  }
}

nav.fixed {
  max-width: 100vw !important;
}

nb-layout-header.fixed {
  max-width: 100vw !important;
  top: 0px !important;
}

.sentry-error-embed {
  margin-top: 100px !important;
}

.full-width {
  width: 100%;
}

nb-layout.window-mode .scrollable-container {
  max-width: 100vw !important;
  height: 100vh !important;
}