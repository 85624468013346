/* You can add global styles to this file, and also import other style files */
.p-radiobutton .p-radiobutton-box.p-highlight {
  border: #9f5fa1;
  background: #9f5fa1;
}

.p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  border: #9f5fa1;
  background: #9f5fa1;
  color: #ffffff;
}

.p-radiobutton .p-highlight:hover {
  border: #9f5fa1;
  background: #9f5fa1;
  color: #ffffff;
}

.p-button-outlined {
  color: #9f5fa1;
  border-color: #9f5fa1;
}

.blueColor {
  color: #5e72c1;
}

.p-blueButton {
  background: #5e72c1;
  border: 1px solid #5e72c1;
  color: #ffffff;
}

.p-blueButton-outlined {
  background: #ffffff;
  border-color: #5e72c1;
  color: #5e72c1;
}

.p-button-outlined {
  background: #9f5fa1;
  border-color: #9f5fa1;
  color: #ffffff;
}

.blueColor {
  color: #5e72c1;
}

.p-blueButton {
  background: #5e72c1;
  border: 1px solid #5e72c1;
  color: #ffffff;
}

.p-blueButton-outlined {
  background: #ffffff;
  border-color: #5e72c1;
  color: #5e72c1;
}

.p-button .p-button-text:enabled:hover {
  background: #9f5fa1;
  color: #333333;
  border-color: transparent;
}

.p-button.p-highlight:hover {
  background: #9f5fa1;
}

.p-card {
  border-radius: 30px;
}

.textColor {
  color: #5e72c1;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  margin-top: 30px;
}

.step3Color {
  color: #5e72c1;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  margin-top: 30px;
}

.textView {
  color: #5e72c1;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  margin-top: 10px;
}

.backgroundColor {
  background: #c8cafd;
  width: auto;
  height: auto;
}

.paraColor {
  color: #5e72c1;
  width: 148px;
  height: 25px;
  margin-left: 136px;
  margin-top: 10px;
}

.imageSize {
  width: 100px;
  height: 30px;
  margin-left: 36px;
  margin-top: 10px;
}

// .mainImgSize {
//   width: 500px;
//   height: 420px;
//   margin-left: 25px;
//   margin-top: 50px;
// }

.ImgSize {
  width: 650px;
  height: 420px;
  margin-left: 25px;
  margin-top: 50px;
}

.cardSize {
  margin-top: 50px;
  margin-left: 50px;
}

.rowSpace {
  margin-top: 1rem;
  font-family: "Source Sans Pro";
}

.inputFieldSize {
  max-width: 60px;
  max-height: 54px;
}

.emailPassFieldSize {
  width: 300px;
  height: 35px;
}

.mobileField {
  width: 200px;
  height: 35px;
}

.nameField {
  width: 300px;
  height: 35px;
}

.p-dropdown.step2Card {

  height: 35px;
}

.btnColor {
  background: #9f5fa1;
  box-shadow: 2px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  font-family: "Source Sans Pro";
  font-weight: 600;
}

.br-10 {
  border-radius: 10px !important;
}

.cliniqallyWhiteColor:hover {
  color: white;
}

.cliniqallyWhiteColor {
  color: white;
}

.cliniqallyButton {
  box-shadow: 2px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  height: 56px !important;
  min-height: 56px !important;
  width: 238px !important;
  font-family: "Source Sans Pro" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
}

.cliniqallyButton:hover {
  color: #5e72c1 !important;
  background-color: #5e72c1 !important;
  border-color: #5e72c1 !important;
  -webkit-transform: scale(1.02);
  transform: scale(1.02);
}

.btn.cliniqallyBlueBackgroundColor:hover {
  color: white !important;
  background-color: #5e72c1 !important;
  border-color: #5e72c1 !important;
  -webkit-transform: scale(1.02);
  transform: scale(1.02);
}

.cliniqallyGradient {
  background: linear-gradient(89.95deg, #5e72c1 44.35%, #7186da 99.96%);
}

.sendOTP {
  background: #5e72c1;
  box-shadow: 2px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  font-family: "Source Sans Pro";
  font-weight: 600;
}

.fontStyleforHyperlink {
  color: #9f5fa1;
  text-decoration: none;
}

.skipTour {
  height: 40px;
  background: #ffffff;
  color: #5e72c1;
  box-shadow: 2px 4px 20px rgba(0, 0, 0, 0.1);
  border: 1px solid #5e72c1;
  border-radius: 10px;
  font-family: "Source Sans Pro";
  font-weight: 600;
}

.beginTour {
  height: 40px;
  background: #5e72c1;
  box-shadow: 2px 4px 20px rgba(0, 0, 0, 0.1);
  border: 1px solid #5e72c1;
  border-radius: 10px;
  font-family: "Source Sans Pro";
  font-weight: 600;
}



.fontStyleforLabel {
  font-family: "Source Sans Pro";
}

.fontStyleforPurple {
  font-family: "Source Sans Pro";
  color: #9f5fa1;
  font-size: 18px;
}

.hyperLinkStyle {
  height: 40px;
  text-decoration: none;
  color: #ffffff;
  background: #5e72c1;
  text-align: right;
  font-family: "Source Sans Pro";
}

.iconColor {
  color: red;
}

.p-datatable .p-datatable-thead>tr>th {
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid #dee2e6;
  border-width: 0 0 1px 0;
  font-weight: 700;
  color: #5e72c1;
  background: #f4f7ff;
  transition: box-shadow 0.2s;
}

.textStyle {
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1px solid #5e72c1;
  color: #151516;
}

.p-menu .p-menuitem-link .p-menuitem-icon {
  color: #9f5fa1;
}

.p-menu .p-menuitem-link .p-menuitem-text {
  color: #5e72c1;
}

.alignDoctor {
  color: #5e72c1;
  font-family: "Source Sans Pro";
  text-align: center;
}

.radioStyle {
  font-family: "Source Sans Pro";
  font-size: 12px;
}

.checkboxAlign {
  margin-left: 4px;
}

.headerText {
  font-family: "Source Sans Pro";
  color: #5e72c1;
}

.saveBtn {
  background: #5e72c1;
  color: #f0f0f0;
  border: 1px solid #5e72c1;
  font-size: 14px;
  height: 35px;
  width: 120px;
  font-family: "Source Sans Pro";
}

.cancelBtn {
  background: #ffffff;
  color: #5e72c1;
  border: 1px solid #5e72c1;
  font-size: 14px;
  height: 35px;
  width: 120px;
  font-family: "Source Sans Pro";
}

.popupInputField {
  width: 170px;
  height: 35px;
}

.popupInput {
  width: 120px;
  height: 35px;
}

.addDrug {
  font-family: "Source Sans Pro";
  color: #5e72c1;
  margin-left: 15px;
}

.searchField {
  width: 400px;
  height: 45px;
  border: 0.5px solid #5e72c1;
}

.popupContentStyle {
  border-radius: 30px;
}

.p-calendar .p-button {
  background-color: white;
  border-left: none;
  border-color: #ced4da;
}

.p-calendar .p-button:hover {
  background-color: white;
  border-left: none;
  border-color: #ced4da;
}

.p-calendar .p-inputtext {
  border-right: none;
}

.p-calendar .p-inputtext:hover {
  border-right: none;
  border-color: #ced4da;
}

span.p-button-icon.pi.pi-calendar {
  color: #5e72c1;
}

.onboardingModelContentBorder {
  border-radius: 30px;
}

.cliniqallyBlueColor {
  color: #5e72c1;
}

.cliniqallyBlueBackgroundColor {
  background-color: #5e72c1;
}

.onboardingModalImage {
  width: 242px;
  height: 206px;
}

.onboardingHeader {
  font-weight: 700;
  font-size: 32px;
  line-height: 50px;
}

.onboardingModalText {
  font-weight: 400;
  font-size: 20px;
  color: #5e72c1;
}

.onboardingModalButton1 {
  background-color: #5e72c1;
  color: white;
  font-size: 16px;
  width: 200px;
  height: 48px;
  border-radius: 4px;
}

.onboardingModalButton2 {
  background-color: white;
  color: #5e72c1;
  font-size: 16px;
  width: 200px;
  height: 48px;
  border-radius: 4px;
  border-color: #677ccd;
  color: #677ccd;
}

.onboardingModalIcon {
  color: #5e72c1;
  font-size: 0.5rem;
}

.cliniqallyBlueBackgroundColor {
  background-color: #5e72c1;
}

.cancelBtn {
  background: #ffffff;
  color: #5e72c1;
  border: 0.2px solid #5e72c1;
  font-family: "Source Sans Pro";
  height: 35px;
  width: 120px;
  border-radius: 0px;
  justify-content: space-around;
}

.updateBtn {
  background: #5e72c1;
  color: #ffffff;
  border: 0.2px solid #5e72c1;
  font-family: "Source Sans Pro";
  height: 35px;
  width: 120px;
  border-radius: 0px;
  justify-content: space-around;
}

.onboardingModalText {
  font-size: 1.3rem;
  color: #5e72c1;
}

.onboardingModalButton1 {
  background-color: #677ccd;
  color: white;
}

.onboardingModalButton2 {
  border-color: #677ccd;
  color: #677ccd;
}

.onboardingModalIcon {
  color: #5e72c1;
  font-size: 0.5rem;
}

.LabelStyle {
  font-family: "Source Sans Pro";
}

.popupLabelStyle {
  font-family: "Source Sans Pro";
  color: #5e72c1;
  font-size: 20px;
}

.popupSubLabelStyle {
  font-family: "Source Sans Pro";
  color: #7d8283;
}

.underLineText {
  font-family: "Source Sans Pro";
  text-decoration: underline;
  font-size: 14px;
}

.subText {
  font-family: "Source Sans Pro";
  color: #8188a1;
  font-size: 14px;
}

.cancelButton {
  color: #5e72c1;
  border-color: #5e72c1;
}

.cardBackground {
  background-color: #f2f3f8;
}

.displayWebKitBox {
  display: -webkit-box;
}

.displayInitial {
  display: initial !important;
}

.p-dropdown .p-dropdown-trigger {
  color: #687bc5;
}

.p-multiselect .p-multiselect-trigger {
  color: #687bc5;
}

.blueText {
  color: #5e72c1;
}

.cardBackground {
  background-color: #f2f3f8;
  overflow: hidden;
}

.patientNumberIcon {
  font-size: 0.5rem;
  color: green;
}

.purpleColor {
  color: #9f5fa1;
}

.sidebar-appointment {
  background: url("./assets/sidebar-icons/appoinment.svg") no-repeat;
}

.sidebar-calendar {
  background: url("./assets/sidebar-icons/calender.svg") no-repeat;
}

.sidebar-add {
  background: url("./assets/sidebar-icons/add.svg") no-repeat;
}

.sidebar-billing {
  background: url("./assets/sidebar-icons/billing.svg") no-repeat;
}

.sidebar-profile {
  background: url("./assets/sidebar-icons/Profile.svg") no-repeat;
}

.sidebar-medicine {
  background: url("./assets/sidebar-icons/medicine.svg") no-repeat;
}

.sidebar-back-office {
  background: url("./assets/sidebar-icons/back-office.svg") no-repeat;
}

.sidebar-know-your-rm {
  background: url("./assets/sidebar-icons/know-your-rm.svg") no-repeat;
}

.sidebar-communication {
  background: url("./assets/sidebar-icons/chat-communication.svg") no-repeat;
}

.sidebar-add-patients {
  background: url("./assets/sidebar-icons/add-patient.svg") no-repeat;
}

.sidebar-mobile-app {
  background: url("./assets/sidebar-icons/mobile-app.svg") no-repeat;
}

.sidebar-faq {
  background: url("./assets/sidebar-icons/faq.svg") no-repeat;
}

.sidebar-settings {
  background: url("./assets/sidebar-icons/settings.svg") no-repeat;
}

.sidebar-medical-staff {
  background: url("./assets/sidebar-icons/medical-staff.svg") no-repeat;
}

.sidebar-rewards {
  background: url("./assets/sidebar-icons/rewards.svg") no-repeat;
}

.sidebar-more {
  background: url("./assets/sidebar-icons/more.svg") no-repeat;
}

.sidebar-invoice {
  background: url("./assets/sidebar-icons/invoice.svg") no-repeat;
}

.sidebar-list {
  background: url("./assets/sidebar-icons/list.svg") no-repeat;
}

.sidebar-template {
  background: url("./assets/sidebar-icons/template.svg") no-repeat;
}

.sidebar-inventory {
  background: url("./assets/sidebar-icons/inventory.svg") no-repeat;
}

.sidebar-expenses {
  background: url("./assets/sidebar-icons/expenses.svg") no-repeat;
}

.sidebar-activities {
  background: url("./assets/sidebar-icons/activities.svg") no-repeat;
}

.sidebar-follow-up-patients {
  background: url("./assets/sidebar-icons/follow-up-patient.svg") no-repeat;
}

.sidebar-qr-code {
  background: url("./assets/sidebar-icons/qr-code.svg") no-repeat;
}

.sidebar-consult {
  background: url("./assets/sidebar-icons/consult.svg") no-repeat;
}

.sidebar-marketing {
  background: url("./assets/sidebar-icons/marketing.svg") no-repeat;
}

.sidebar-health-feed {
  background: url("./assets/sidebar-icons/health-feed.svg") no-repeat;
}

.cliniqally-calendar {
  background: url("./assets/images/calender-icon.png") no-repeat;
}

.cliniqally-calendar::before {
  content: "";
  width: 17px !important;
  height: 17px !important;
  display: table-cell;
}

.vaas-icon::before {
  content: "";
  display: table-cell;
  width: 32px;
  height: 32px;
}

.marginBottom {
  margin-bottom: 80px;
}

.paddingStyle {
  padding: 0px;
}

.cliniqallyInputBox {
  max-height: 44px !important;
}

.cliniqallyButtonTwo {
  background: linear-gradient(90deg, #5e72c1 11.79%, #3952b4 100%) !important;
  box-shadow: 2px 4px 20px rgba(0, 0, 0, 0.1) !important;
}

.cliniqallyButtonThree {
  background: linear-gradient(89.95deg, #5e72c1 44.35%, #7186da 99.96%);
  color: white;
  border-radius: 4px;
}

.cliniqallyButtonTwo span,
.cliniqallyButtonThree span {
  font-weight: 500 !important;
}

.lottie {
  max-width: 100%;
  height: auto;
}

.spinAnimation {
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

// .form-label {
//   padding-left: 1rem;
//   line-height: 1.2;
//   text-transform: uppercase;
//   margin-bottom: 0;
//   font-size: 0.64rem;
//   font-weight: 700;
// }

.fnt-20 {
  font-size: 20px;
}

body {
  overflow: auto !important;
}